import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import faqQuestionClass from "../faq-question/faq.question.module.scss";
import Accordion from "../faq-accordion";
import PlusIcon from "../plus-icon";

function FaqQuestionContent({
  isShow,
  amountVisibleQuetions,
  questions,
  changeActiveQuestion,
  idButtonActive,
}) {
  return (
    <div className={faqQuestionClass.faqQuestions}>
      {questions.map(({ title, body, active }, key) => {
        if (key < amountVisibleQuetions) {
          return (
            <div key={key} className={faqQuestionClass.faqQuestion}>
              <Accordion
                idButtonActive={idButtonActive}
                title={
                  <h3 className={faqQuestionClass.faqTitleText}>{title}</h3>
                }
                button={
                  <PlusIcon
                    className={classNames(faqQuestionClass.icon, {
                      [`${faqQuestionClass.activeIcon}`]: active,
                    })}
                  />
                }
                body={
                  <div className={faqQuestionClass.faqDescription}>{body}</div>
                }
                onToggle={(state) =>
                  changeActiveQuestion(state, key, questions)
                }
              />
            </div>
          );
        }
      })}
    </div>
  );
}

FaqQuestionContent.propTypes = {
  isShow: PropTypes.bool,
};
FaqQuestionContent.defaultProps = {
  isShow: true,
};

export default FaqQuestionContent;
