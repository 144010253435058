import React, { useEffect, useState } from "react";
import { Collapse } from "react-collapse";
import accordionClasses from "./accordion.module.scss";
import classNames from "classnames";
const Accordion = function ({
  title = "",
  initState = false,
  button = "",
  onOpen = () => {},
  onClose = () => {},
  body = "",
  onToggle = () => {},
  titleClassName,
  className,
  idButtonActive
 
}) {

const [state, setState] = useState(initState);
  const onChange = () => {
    const newState = !state;
    setState(newState)
    onToggle(newState);
  };

useEffect(()=>{
  return()=>{
    setState(false)
    onToggle(false)
  }
},[idButtonActive])
  return (
    <div className={classNames(accordionClasses["accordion"], className)}>
      <div className={classNames(accordionClasses["titleSection"], titleClassName)} onClick={onChange}>
        <div className={accordionClasses["title"]}>{title}</div>
        <div className={accordionClasses["button"]}>{button}</div>
      </div>
      <Collapse isOpened={state}>{body}</Collapse>  
    </div>
  );
};

export default Accordion;
