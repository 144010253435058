import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import classNames from "./faq.company.module.scss";
import AlzaIcon from "../../icons/faq-company/alza/alza.png";
import GymbeamIcon from "../../icons/faq-company/gymbeam/gymbeam.png";
import VictorIcon from "../../icons/faq-company/victor/victor.png";
import AutoDielyIcon from "../../icons/faq-company/autodiely/autodiely.png";

function FaqCompany({ isShow }) {
  return (
    <>
      <div className={classNames.faqRectangle}>
        <h2 className={classNames.title}>
          Máte riešenie aj{" "}
          <span className={classNames.title}>pre moju firmu?</span>
        </h2>
        <p className={classNames.faqDescription}>
          Už desiatky firiem prepravujú svoje zásielky v Košiciach a z Košíc do
          ČR cez Doruč.to. Spoznajte našu ponuku v sekcii{" "}
          <Link className={"link"} to={"#"}>
            Pre firmy
          </Link>
          , kontaktujte nás a radi navrhneme riešenie aj pre vás a vašich
          zákazníkov
        </p>
        <div className={classNames.btn}>
          <Link to="for-companies" className={classNames.button}>
            Ísť do sekcie Pre firmy
          </Link>
        </div>
        <div className={classNames.icons}>
          <div>
            <img src={AlzaIcon} alt="Alza logo" width="110" />
          </div>
          <div>
            <img src={GymbeamIcon} alt="Gymbeam logo" width="121" />
          </div>
          <div>
            <img src={VictorIcon} alt="Victor logo" width="106" />
          </div>
          <div>
            <img src={AutoDielyIcon} alt="AutoDiely logo" width="123" />
          </div>
        </div>
      </div>
    </>
  );
}

FaqCompany.propTypes = {
  isShow: PropTypes.bool,
};
FaqCompany.defaultProps = {
  isShow: true,
};

export default FaqCompany;
