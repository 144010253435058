import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Button from "../button";
import faqQuestionClass from "./faq.question.module.scss";
import { faqButtons } from "../../constants/homepage";
import FaqQuestionContent from "../faq-question-content";
import FaqCompany from "../faq-company";
import ArrowButtonIcon from "../arrow-button-icon";
import {
  SHOW_FAQ_QUESTIONS_AT_ONCE_KE_KE,
  SHOW_FAQ_QUESTIONS_AT_ONCE_KE_CZ,
} from "../../constants/faq";
import { QUESTIONS_KE_KE, QUESTIONS_KE_CZ } from "../../constants/homepage";

function FaqQuestion({
  typeQuestions,
  typePage,
  tabs,
  title,
  showMore,
  location,
}) {
  const [faqButton, setActive] = useState(
    faqButtons.map((button) => ({ ...button }))
  );
  const [isShow, setIsShow] = useState(true);
  const [isShowComp, setIsShowComp] = useState(false);
  const [isShowMore, setIsShowMore] = useState(showMore);
  const [questions, setQuestions] = useState(typeQuestions);

  const [amountVisibleQuetions, setAmountVisibleQuestions] = useState(
    SHOW_FAQ_QUESTIONS_AT_ONCE_KE_KE
  );
  const [idButtonActive, setIdButtonActive] = useState(faqButton[1].id);

  const changeActiveBtn = (state, key) => {
    const newFaqButtons = faqButton;
    for (let i = 0; i < newFaqButtons.length; i++) {
      if (key !== i) {
        newFaqButtons[i].active = false;
      } else {
        newFaqButtons[i].active = true;
        if (newFaqButtons[i].id === 3) {
          setIsShowComp(true);
          setIsShow(false);
          setIdButtonActive(newFaqButtons[i].id);
        }
        if (newFaqButtons[i].id === 2) {
          setIsShowComp(false);
          setIsShow(true);
          setQuestions(QUESTIONS_KE_KE);
          setAmountVisibleQuestions(SHOW_FAQ_QUESTIONS_AT_ONCE_KE_KE);
          setIdButtonActive(newFaqButtons[i].id);
        }
        if (newFaqButtons[i].id === 1) {
          setIsShowComp(false);
          setIsShow(true);
          setQuestions(QUESTIONS_KE_CZ);
          setAmountVisibleQuestions(SHOW_FAQ_QUESTIONS_AT_ONCE_KE_CZ);
          setIdButtonActive(newFaqButtons[i].id);
        }
      }
    }
    setActive([...newFaqButtons]);
    setIsShowMore(true);
  };

  const handleAdjustAmountQuetions = (questions, showAtOnce) => {
    setIsShowMore(!showMore);
    setAmountVisibleQuestions(questions.length + showAtOnce);
  };
  const changeActiveQuestion = (state, key, questions) => {
    questions[key].active = state;
  };

  return (
    <div className={faqQuestionClass.faqSection}>
      <div className={faqQuestionClass.faqTitle}>
        {typePage === "homepage" ? <h2>{title}</h2> : <h1>{title}</h1>}
      </div>

      <div className={faqQuestionClass.faqBody}>
        {tabs && (
          <div className={faqQuestionClass.faqTabs}>
            {faqButton.map(({ text, active }, key) => {
              return (
                <Button
                  key={key}
                  onClick={(state) => changeActiveBtn(state, key)}
                  className={classNames(faqQuestionClass.faqTab, {
                    [`${faqQuestionClass.active}`]: active,
                  })}
                >
                  {text}
                </Button>
              );
            })}
          </div>
        )}
        {isShow && !isShowComp ? (
          <FaqQuestionContent
            isShow={isShow}
            idButtonActive={idButtonActive}
            amountVisibleQuetions={amountVisibleQuetions}
            questions={questions}
            changeActiveQuestion={changeActiveQuestion}
          />
        ) : (
          <FaqCompany isShow={isShowComp} />
        )}

        {isShowMore && !isShowComp ? (
          <div className={faqQuestionClass.faqButtonSection}>
            <div
              onClick={() =>
                handleAdjustAmountQuetions(questions, amountVisibleQuetions)
              }
              className={faqQuestionClass.faqNextButton}
            >
              <span>Pozrieť všetky otázky</span>
              <span className={faqQuestionClass.arrow}>
                <ArrowButtonIcon />
              </span>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
FaqQuestion.propTypes = {
  typePage: PropTypes.string,
  tabs: PropTypes.bool,
  title: PropTypes.string,
  showMore: PropTypes.bool,
};
FaqQuestion.defaultProps = {
  showMore: true,
  typePage: "homepage",
  tabs: true,
  title: "Časté otázky",
};
export default FaqQuestion;
