import React, { useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Page from "../page";
import classNames from "./cookie-banner.module.scss";
import Button from "../button";
import CookieSetting from "../cookie-setting";
import { cookiePolicyAgreement } from "../../services/acceptedCookie";
import {ACCEPTED_COOKIES, PRIVACY_POLICY} from "../../constants/homepage";

function CookieBanner({ setIsAcceptedCookie }) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <Page>
      <CookieSetting
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        setIsAcceptedCookie={setIsAcceptedCookie}
      />
      <div className={classNames.cookieBanner}>
        <div className={classnames("container", classNames.content)}>
          <p>
            Táto webová stránka používa súbory cookies. Prehliadaním webovej stránky vyjadrujete súhlas s ich pouźívaním. <a href={PRIVACY_POLICY} target={"_blank"} className={classNames.learnMoreLink}>Zistiť viac.</a>
          </p>
          <div className={classNames.actions}>
            <u
              className={classNames.link}
              onClick={() => {
                setOpenModal(true);
              }}
            >
              Nastavenia cookies
            </u>
            <Button
              color="green"
              className={classNames.button}
              onClick={() => {
                setIsAcceptedCookie(true);
                cookiePolicyAgreement(ACCEPTED_COOKIES);
              }}
            >
              AKCEPTOVAŤ
            </Button>
          </div>
        </div>
      </div>
    </Page>
  );
}

CookieBanner.propTypes = {
  onClickManuallyAdd: PropTypes.func,
};
CookieBanner.defaultProps = {
  onClickManuallyAdd: () => {
    return 0;
  },
};

export default CookieBanner;
