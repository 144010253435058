import React from "react";

function BoxImage({ className }) {
    return (
        <svg width="162" height="127" viewBox="0 0 162 127" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M154.799 50.1014H108.553H102.118C98.1601 50.1014 94.9426 53.3395 94.9426 57.266C94.9426 61.2181 98.1857 64.4307 102.118 64.4307H118.461C122.419 64.4307 125.637 67.6688 125.637 71.5953C125.637 75.5473 122.394 78.7599 118.461 78.7599H112.869C108.911 78.7599 105.693 81.998 105.693 85.9245C105.693 89.8765 108.936 93.0892 112.869 93.0892H115.142C119.1 93.0892 122.317 96.3273 122.317 100.254C122.317 104.206 119.074 107.418 115.142 107.418H19.203C15.245 107.418 12.0274 104.18 12.0274 100.254C12.0274 96.3018 15.2705 93.0892 19.203 93.0892H19.4839C23.442 93.0892 26.6595 89.8511 26.6595 85.9245C26.6595 81.9725 23.4165 78.7599 19.4839 78.7599H7.1756C3.24306 78.7854 0 75.5728 0 71.6208C0 67.6688 3.24306 64.4562 7.1756 64.4562H28.8045C32.7626 64.4562 35.9801 61.2181 35.9801 57.2915C35.9801 53.3395 32.7371 50.1269 28.8045 50.1269H21.9864H18.7689C14.8108 50.1269 11.5933 46.8888 11.5933 42.9623C11.5933 39.0103 14.8364 35.7977 18.7689 35.7977H56.7919C60.75 35.7977 63.9675 32.5596 63.9675 28.633C63.9675 24.681 60.7245 21.4684 56.7919 21.4684H50.2037C46.2456 21.4684 43.0281 18.2303 43.0281 14.3038C43.0281 10.3518 46.2711 7.13916 50.2037 7.13916H149.743C153.701 7.13916 156.918 10.3773 156.918 14.3038C156.918 18.2558 153.675 21.4684 149.743 21.4684H147.342C143.384 21.4684 140.167 24.7065 140.167 28.633C140.167 32.5851 143.41 35.7977 147.342 35.7977H154.799C158.757 35.7977 161.974 39.0358 161.974 42.9623C162 46.8888 158.757 50.1014 154.799 50.1014Z" fill="url(#paint0_linear)"/>
            <path d="M36.644 100.305H19.7393" stroke="#D2E8F8" strokeWidth="20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M152.679 42.9368H135.647" stroke="#D2E8F8" strokeWidth="20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M151.096 105.71C147.24 106.781 146.755 107.342 145.759 111.727C145.632 112.339 145.07 112.798 144.431 112.798C143.793 112.798 143.231 112.365 143.103 111.727C142.133 107.342 141.622 106.781 137.766 105.71C137.179 105.557 136.771 104.996 136.771 104.384C136.771 103.772 137.179 103.237 137.766 103.058C141.622 101.987 142.108 101.427 143.103 97.0411C143.231 96.4292 143.793 95.9702 144.431 95.9702C145.07 95.9702 145.632 96.4037 145.759 97.0411C146.755 101.427 147.24 101.987 151.096 103.058C151.684 103.211 152.092 103.772 152.092 104.384C152.092 105.022 151.684 105.557 151.096 105.71Z" fill="#FFC92F"/>
            <path d="M28.8302 6.62919C26.2 7.3686 25.868 7.75105 25.1786 10.7342C25.0764 11.1676 24.6934 11.4736 24.2593 11.4736C23.8252 11.4736 23.4421 11.1676 23.34 10.7342C22.6761 7.75105 22.3185 7.3686 19.7139 6.62919C19.3053 6.5272 19.0244 6.14475 19.0244 5.7368C19.0244 5.32885 19.3053 4.9464 19.7139 4.84441C22.3441 4.105 22.6761 3.72255 23.34 0.73941C23.4677 0.305963 23.8507 0 24.2848 0C24.7189 0 25.102 0.305963 25.2041 0.73941C25.868 3.72255 26.2255 4.105 28.8558 4.84441C29.2643 4.9464 29.5452 5.32885 29.5452 5.7368C29.5197 6.14475 29.2388 6.5272 28.8302 6.62919Z" fill="#FFC92F"/>
            <path d="M80.9999 127C82.4102 127 83.5535 125.858 83.5535 124.45C83.5535 123.042 82.4102 121.901 80.9999 121.901C79.5896 121.901 78.4463 123.042 78.4463 124.45C78.4463 125.858 79.5896 127 80.9999 127Z" fill="#FFC92F"/>
            <path d="M6.61365 93.1402C8.02396 93.1402 9.16725 91.9986 9.16725 90.5905C9.16725 89.1823 8.02396 88.0408 6.61365 88.0408C5.20334 88.0408 4.06006 89.1823 4.06006 90.5905C4.06006 91.9986 5.20334 93.1402 6.61365 93.1402Z" fill="#FFC92F"/>
            <path d="M26.8638 26.8482L81 0L135.111 26.8482V30.3923L81 57.2405L26.8893 30.4688V26.8482H26.8638Z" fill="#FFD458"/>
            <path d="M26.8638 26.8481L80.9744 53.6964L135.085 26.8481V80.6211L80.8212 107.469L26.8638 80.6211V26.8481Z" fill="#FFC92F"/>
            <path d="M80.9998 107.393V53.6964L27.2211 27.0266H26.8892V80.6466L80.8466 107.495L80.9998 107.393Z" fill="#FFB618"/>
            <path d="M48.9521 15.961L102.986 42.8092L113.967 37.4039L59.7794 10.5557L48.9521 15.961Z" fill="#E84C1C"/>
            <path d="M102.986 42.8091L113.967 37.4038V54.2827L111.005 53.7728L107.864 57.5718L102.961 57.2404C102.986 57.2404 102.986 42.8091 102.986 42.8091Z" fill="#E23C1A"/>
            <path d="M69.8407 75.1393L51.557 66.0369C50.5356 65.527 49.6929 66.1134 49.6929 67.3882V79.6267C49.6929 80.9016 50.5356 82.3294 51.557 82.8394L69.9173 91.9673C70.9388 92.4772 71.7815 91.8908 71.7815 90.6159V78.2754C71.7049 77.0006 70.8366 75.6492 69.8407 75.1393ZM65.6784 85.1851L54.0084 79.3718C53.4977 79.1168 53.0891 78.3519 53.0891 77.689C53.0891 77.0261 53.5233 76.6691 54.0084 76.9241L65.6784 82.7374C66.1891 82.9923 66.5977 83.7572 66.5977 84.4202C66.6232 85.1086 66.1891 85.44 65.6784 85.1851ZM67.3893 80.9781L54.034 74.2214C53.5233 73.9664 53.1147 73.2015 53.1147 72.5386C53.1147 71.8757 53.5488 71.5187 54.034 71.7737L67.3893 78.5304C67.9 78.7853 68.3086 79.5503 68.3086 80.2132C68.3086 80.9781 67.9 81.2331 67.3893 80.9781Z" fill="white"/>
            <defs>
                <linearGradient id="paint0_linear" x1="0" y1="57.2751" x2="161.99" y2="57.2751" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BADCF5"/>
                    <stop offset="1" stopColor="#81C9F5"/>
                </linearGradient>
            </defs>
        </svg>
    );
}

export default BoxImage;
