import React from "react";
import { contacts } from "../../constants/homepage";
import Contact from "../contact";
import contactUsClasses from "./contact.us.module.scss";
import { CONTACT_US_ID } from "../../constants/contact-us";

function ContactUs() {
  return (
    <section id={CONTACT_US_ID} className={contactUsClasses.contactsSection}>
      <h2 className={contactUsClasses.title}>Kontaktujte nás</h2>
      <div className={contactUsClasses.listContacts}>
        {contacts.map((contactOptions, index) => (
          <Contact key={index} {...contactOptions} />
        ))}
      </div>
    </section>
  );
}

export default ContactUs;
