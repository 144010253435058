import React from "react";

function ArrowButtonIcon() {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7H13" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 1L13 7L7 13" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default ArrowButtonIcon;
