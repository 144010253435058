import React from "react";
import contactClasses from "./contact.module.scss";
import classNames from "classnames";
import Underline from "../undreline";

const Contact = function ({image, title, description, className, href = null, display = "flex", onClick}) {
    return (
        <div className={classNames(contactClasses["contact"], contactClasses[display], className)}>
            <div className={contactClasses["image"]}>{image}</div>
            <div>
                <p className={contactClasses["title"]}>{title}</p>
                {href ? (
                    <a href={href} className={contactClasses["description"]}>
                        {description}
                    </a>
                ) : onClick ? (
                    <button onClick={onClick} className={classNames(contactClasses["description"], contactClasses["button"])}>{description}</button>
                ) : (
                    <p className={contactClasses["description"]}>
                        {description}
                    </p>
                )}

            </div>
        </div>
    );
};

export default Contact;
