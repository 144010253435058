import React, {useEffect, useState} from "react";

function PlusIcon({ color = "#000", className }) {
    const [currentColor, setColor] = useState(color);
    useEffect(() => setColor(color), [color]);
    return (
        <svg className={className} width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M12 5.5V19.5" stroke={currentColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 12.5H19" stroke={currentColor} strokeWidth="2" strokeLinecap="round" strokelnejoin="round"/>
        </svg>
    );
}

export default PlusIcon;



